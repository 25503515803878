<template>
	<v-app>
		<topsidebar @menudrawer="menudrawer = !menudrawer" @connect="connectWalletLogin" @disconnect="disconnectWallet" :connected="connected" :wallet="wallet" />
		<v-main :class="menuclass">
			
			<router-view  :connected="connected" :balance="balance" :claimbalance="claimbalance" :claimbalanceETH="claimbalanceETH" :claimbalanceAS="claimbalanceAS" :status="status" :loading="loading" :txn="txn" :wallet="wallet" @sacrificefor="sacrificefor" @sacrifice="sacrifice" @claimrewards="claimrewards" @claim="claim" @reset="reset"></router-view>
		</v-main>
	</v-app>
</template>

<script>
// @ is an alias to /src

import topsidebar from "../components/topsidebar.vue"

export default {
	name: 'Home',
	components: {
		topsidebar
	},
	props: {
		wallet: String,
		connected: Boolean,
		loading: Boolean,
		status: String,
		txn: String,
		balance: Number,
		claimbalance: Number,
		claimbalanceETH: Number,
		claimbalanceAS: Number,

		

	},
	data: () => ({
		menudrawer: true,
	}),
	computed: {
		menuclass() {
			return this.menudrawer == true ? "active" : "";
		}
	},
	methods: {
		connectWalletLogin() {
			this.$emit('connect');
		},
		disconnectWallet() {
			this.$emit('disconnect');
		},
		sacrifice(value){
			this.$emit("sacrifice",value);
		},
		sacrificefor(value,wallet){
			this.$emit("sacrificefor",value,wallet);
		},
		claim() {
			this.$emit("claim");
		},
		claimrewards() {
			this.$emit("claimrewards");
		},
		reset(){
			this.$emit("reset");
		},
		

	},
}
</script>
