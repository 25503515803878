<template>
	<div>
		<v-app-bar elevation="0" outlined rounded app :class="`px-6 py-6 ${menuclass}`">
			<v-app-bar-nav-icon color="light" @click="menuDrawer = !menuDrawer"></v-app-bar-nav-icon>

			<v-toolbar-title>{{titlename}}</v-toolbar-title>
			<v-spacer></v-spacer>
			<userPill @disconnect="disconnectWallet" @connect="connectWalletLogin" :connected="connected" :wallet="wallet" />
		</v-app-bar>
		<v-navigation-drawer v-model="menuDrawer" class="sidebar" app outlined rounded>
			<v-list>
				<v-list-item>
					<v-list-item-content>
						<v-img class="mx-auto d-block" lazy-src="~@/assets/cs_logo.svg" max-height="150" max-width="150" src="~@/assets/cs_logo.svg"></v-img>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/">
					<v-list-item-icon>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<mask id="mask0_1030_1992" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
								<rect width="32" height="32" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1030_1992)">
								<path d="M17.9999 12.0002V5.3335H26.6666V12.0002H17.9999ZM5.33325 16.0002V5.3335H13.9999V16.0002H5.33325ZM17.9999 26.6668V16.0002H26.6666V26.6668H17.9999ZM5.33325 26.6668V20.0002H13.9999V26.6668H5.33325ZM6.66659 14.6668H12.6666V6.66683H6.66659V14.6668ZM19.3333 25.3335H25.3333V17.3335H19.3333V25.3335ZM19.3333 10.6668H25.3333V6.66683H19.3333V10.6668ZM6.66659 25.3335H12.6666V21.3335H6.66659V25.3335Z" fill="white" />
							</g>
						</svg>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>DASHBOARD</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/polls">
					<v-list-item-icon>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<mask id="mask0_1030_1984" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
								<rect width="32" height="32" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1030_1984)">
								<path d="M15.9999 13.3335H22.1538V12.0002H15.9999V13.3335ZM15.9999 20.0002H22.1538V18.6668H15.9999V20.0002ZM11.9999 14.3079C12.4597 14.3079 12.8482 14.1493 13.1653 13.8322C13.4824 13.5151 13.641 13.1267 13.641 12.6668C13.641 12.207 13.4824 11.8185 13.1653 11.5014C12.8482 11.1843 12.4597 11.0258 11.9999 11.0258C11.5401 11.0258 11.1516 11.1843 10.8345 11.5014C10.5174 11.8185 10.3589 12.207 10.3589 12.6668C10.3589 13.1267 10.5174 13.5151 10.8345 13.8322C11.1516 14.1493 11.5401 14.3079 11.9999 14.3079ZM11.9999 20.9745C12.4597 20.9745 12.8482 20.816 13.1653 20.4989C13.4824 20.1818 13.641 19.7933 13.641 19.3335C13.641 18.8737 13.4824 18.4852 13.1653 18.1681C12.8482 17.851 12.4597 17.6925 11.9999 17.6925C11.5401 17.6925 11.1516 17.851 10.8345 18.1681C10.5174 18.4852 10.3589 18.8737 10.3589 19.3335C10.3589 19.7933 10.5174 20.1818 10.8345 20.4989C11.1516 20.816 11.5401 20.9745 11.9999 20.9745ZM7.48709 26.6668C6.87342 26.6668 6.36103 26.4613 5.94992 26.0502C5.53881 25.6391 5.33325 25.1267 5.33325 24.513V7.48733C5.33325 6.87366 5.53881 6.36127 5.94992 5.95016C6.36103 5.53905 6.87342 5.3335 7.48709 5.3335H24.5128C25.1264 5.3335 25.6388 5.53905 26.0499 5.95016C26.461 6.36127 26.6666 6.87366 26.6666 7.48733V24.513C26.6666 25.1267 26.461 25.6391 26.0499 26.0502C25.6388 26.4613 25.1264 26.6668 24.5128 26.6668H7.48709ZM7.48709 25.3335H24.5128C24.7179 25.3335 24.9059 25.248 25.0769 25.0771C25.2478 24.9061 25.3333 24.7181 25.3333 24.513V7.48733C25.3333 7.28222 25.2478 7.09419 25.0769 6.92323C24.9059 6.7523 24.7179 6.66683 24.5128 6.66683H7.48709C7.28197 6.66683 7.09394 6.7523 6.92299 6.92323C6.75205 7.09419 6.66659 7.28222 6.66659 7.48733V24.513C6.66659 24.7181 6.75205 24.9061 6.92299 25.0771C7.09394 25.248 7.28197 25.3335 7.48709 25.3335Z" fill="white" />
							</g>
						</svg>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>POLLS</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/polls/create" :disabled="!connected">
					<v-list-item-icon>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<mask id="mask0_1030_1985" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
								<rect width="32" height="32" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1030_1985)">
								<path d="M15.3333 22H16.6667V16.6667H22V15.3333H16.6667V10H15.3333V15.3333H10V16.6667H15.3333V22ZM16.0045 28C14.3451 28 12.785 27.6851 11.3241 27.0554C9.86331 26.4256 8.59259 25.5709 7.51197 24.4913C6.43137 23.4117 5.57589 22.1422 4.94553 20.6827C4.31518 19.2233 4 17.6638 4 16.0045C4 14.3451 4.31488 12.785 4.94463 11.3241C5.57441 9.86331 6.4291 8.59259 7.5087 7.51197C8.5883 6.43137 9.85782 5.57589 11.3173 4.94553C12.7767 4.31518 14.3362 4 15.9955 4C17.6549 4 19.215 4.31488 20.6759 4.94463C22.1367 5.57441 23.4074 6.4291 24.488 7.5087C25.5686 8.5883 26.4241 9.85782 27.0545 11.3173C27.6848 12.7767 28 14.3362 28 15.9955C28 17.6549 27.6851 19.215 27.0554 20.6759C26.4256 22.1367 25.5709 23.4074 24.4913 24.488C23.4117 25.5686 22.1422 26.4241 20.6827 27.0545C19.2233 27.6848 17.6638 28 16.0045 28ZM16 26.6667C18.9778 26.6667 21.5 25.6333 23.5667 23.5667C25.6333 21.5 26.6667 18.9778 26.6667 16C26.6667 13.0222 25.6333 10.5 23.5667 8.43333C21.5 6.36667 18.9778 5.33333 16 5.33333C13.0222 5.33333 10.5 6.36667 8.43333 8.43333C6.36667 10.5 5.33333 13.0222 5.33333 16C5.33333 18.9778 6.36667 21.5 8.43333 23.5667C10.5 25.6333 13.0222 26.6667 16 26.6667Z" fill="white" />
							</g>
						</svg>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>CREATE A POLL</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/sacrifice">
					<v-list-item-icon>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<mask id="mask0_1115_3520" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
								<rect width="32" height="32" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1115_3520)">
								<path d="M12.9231 23.0769L13.8667 22.1333L11.0821 19.3333H17.3333V18H11.0821L13.8667 15.2L12.9231 14.2564L8.5128 18.6667L12.9231 23.0769ZM19.0769 17.7436L23.4872 13.3333L19.0769 8.92307L18.1333 9.86667L20.9179 12.6667H14.6667V14H20.9179L18.1333 16.8L19.0769 17.7436ZM16.0045 28C14.3451 28 12.785 27.6851 11.3241 27.0554C9.86331 26.4256 8.59259 25.5709 7.51197 24.4913C6.43137 23.4117 5.57589 22.1422 4.94553 20.6827C4.31518 19.2233 4 17.6638 4 16.0045C4 14.3451 4.31488 12.785 4.94463 11.3241C5.57441 9.86331 6.4291 8.59259 7.5087 7.51197C8.5883 6.43137 9.85782 5.57589 11.3173 4.94553C12.7767 4.31518 14.3362 4 15.9955 4C17.6549 4 19.215 4.31488 20.6759 4.94463C22.1367 5.57441 23.4074 6.4291 24.488 7.5087C25.5686 8.5883 26.4241 9.85782 27.0545 11.3173C27.6848 12.7767 28 14.3362 28 15.9955C28 17.6549 27.6851 19.215 27.0554 20.6759C26.4256 22.1367 25.5709 23.4074 24.4913 24.488C23.4117 25.5686 22.1422 26.4241 20.6827 27.0545C19.2233 27.6848 17.6638 28 16.0045 28ZM16 26.6667C18.9778 26.6667 21.5 25.6333 23.5667 23.5667C25.6333 21.5 26.6667 18.9778 26.6667 16C26.6667 13.0222 25.6333 10.5 23.5667 8.43333C21.5 6.36667 18.9778 5.33333 16 5.33333C13.0222 5.33333 10.5 6.36667 8.43333 8.43333C6.36667 10.5 5.33333 13.0222 5.33333 16C5.33333 18.9778 6.36667 21.5 8.43333 23.5667C10.5 25.6333 13.0222 26.6667 16 26.6667Z" fill="white" />
							</g>
						</svg>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>SACRIFICE</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/auto-sacrifice">
					<v-list-item-icon>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<mask id="mask0_1115_3520" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
								<rect width="32" height="32" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1115_3520)">
								<path d="M12.9231 23.0769L13.8667 22.1333L11.0821 19.3333H17.3333V18H11.0821L13.8667 15.2L12.9231 14.2564L8.5128 18.6667L12.9231 23.0769ZM19.0769 17.7436L23.4872 13.3333L19.0769 8.92307L18.1333 9.86667L20.9179 12.6667H14.6667V14H20.9179L18.1333 16.8L19.0769 17.7436ZM16.0045 28C14.3451 28 12.785 27.6851 11.3241 27.0554C9.86331 26.4256 8.59259 25.5709 7.51197 24.4913C6.43137 23.4117 5.57589 22.1422 4.94553 20.6827C4.31518 19.2233 4 17.6638 4 16.0045C4 14.3451 4.31488 12.785 4.94463 11.3241C5.57441 9.86331 6.4291 8.59259 7.5087 7.51197C8.5883 6.43137 9.85782 5.57589 11.3173 4.94553C12.7767 4.31518 14.3362 4 15.9955 4C17.6549 4 19.215 4.31488 20.6759 4.94463C22.1367 5.57441 23.4074 6.4291 24.488 7.5087C25.5686 8.5883 26.4241 9.85782 27.0545 11.3173C27.6848 12.7767 28 14.3362 28 15.9955C28 17.6549 27.6851 19.215 27.0554 20.6759C26.4256 22.1367 25.5709 23.4074 24.4913 24.488C23.4117 25.5686 22.1422 26.4241 20.6827 27.0545C19.2233 27.6848 17.6638 28 16.0045 28ZM16 26.6667C18.9778 26.6667 21.5 25.6333 23.5667 23.5667C25.6333 21.5 26.6667 18.9778 26.6667 16C26.6667 13.0222 25.6333 10.5 23.5667 8.43333C21.5 6.36667 18.9778 5.33333 16 5.33333C13.0222 5.33333 10.5 6.36667 8.43333 8.43333C6.36667 10.5 5.33333 13.0222 5.33333 16C5.33333 18.9778 6.36667 21.5 8.43333 23.5667C10.5 25.6333 13.0222 26.6667 16 26.6667Z" fill="white" />
							</g>
						</svg>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>AUTOSACRIFICE</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/claiming">
					<v-list-item-icon>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<mask id="mask0_1115_3520" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
								<rect width="32" height="32" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1115_3520)">
								<path d="M12.9231 23.0769L13.8667 22.1333L11.0821 19.3333H17.3333V18H11.0821L13.8667 15.2L12.9231 14.2564L8.5128 18.6667L12.9231 23.0769ZM19.0769 17.7436L23.4872 13.3333L19.0769 8.92307L18.1333 9.86667L20.9179 12.6667H14.6667V14H20.9179L18.1333 16.8L19.0769 17.7436ZM16.0045 28C14.3451 28 12.785 27.6851 11.3241 27.0554C9.86331 26.4256 8.59259 25.5709 7.51197 24.4913C6.43137 23.4117 5.57589 22.1422 4.94553 20.6827C4.31518 19.2233 4 17.6638 4 16.0045C4 14.3451 4.31488 12.785 4.94463 11.3241C5.57441 9.86331 6.4291 8.59259 7.5087 7.51197C8.5883 6.43137 9.85782 5.57589 11.3173 4.94553C12.7767 4.31518 14.3362 4 15.9955 4C17.6549 4 19.215 4.31488 20.6759 4.94463C22.1367 5.57441 23.4074 6.4291 24.488 7.5087C25.5686 8.5883 26.4241 9.85782 27.0545 11.3173C27.6848 12.7767 28 14.3362 28 15.9955C28 17.6549 27.6851 19.215 27.0554 20.6759C26.4256 22.1367 25.5709 23.4074 24.4913 24.488C23.4117 25.5686 22.1422 26.4241 20.6827 27.0545C19.2233 27.6848 17.6638 28 16.0045 28ZM16 26.6667C18.9778 26.6667 21.5 25.6333 23.5667 23.5667C25.6333 21.5 26.6667 18.9778 26.6667 16C26.6667 13.0222 25.6333 10.5 23.5667 8.43333C21.5 6.36667 18.9778 5.33333 16 5.33333C13.0222 5.33333 10.5 6.36667 8.43333 8.43333C6.36667 10.5 5.33333 13.0222 5.33333 16C5.33333 18.9778 6.36667 21.5 8.43333 23.5667C10.5 25.6333 13.0222 26.6667 16 26.6667Z" fill="white" />
							</g>
						</svg>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>CLAIMING</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
    
<script>
import userPill from "../components/Userpill.vue"
export default {

	components: {
		userPill
	},
	props: {
		wallet: String,
		connected: Boolean,
		title: String,
	},
	computed: {
		menuclass() {
			return this.menuDrawer == true ? "active" : "";
		},
		titlename() {
			return this.$route.name == ":id" ? this.title : this.$route.name;

		}
	},
	methods: {
		connectWalletLogin() {
			this.$emit('connect');
		},
        disconnectWallet() {
			this.$emit('disconnect');
		},
	},
	data: () => ({
		menuDrawer: true,
	}),
	watch: {
		menuDrawer() {
			this.$emit('menudrawer');
		}
	}

}
</script>